html,
body,
#root {
  margin: 0;
  padding: 0;
  background-color: white;
}

body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  background: #f0f0f0;
  height: 900px;
  background: blueviolet;
  width: 100%;
}

.card1,
.card2,
.card3,
.card4,
.card5 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 60ch;
  min-height: 60ch;
  width: 35vw;
  height: 45vw;
  max-width: 100ch;
  max-height: 100ch;
  z-index: 9;
}

.card2 {
  position: relative;
  width: 60ch;
  height: 60ch;
  top: 40px;
  left: 180px;
  z-index: 9;
}

.card3 {
  position: relative;
  top: 40px;
  left: -110px;
  opacity: 0.9;
  width: 40ch;
  height: 40ch;
}

.card4 {
  width: 60ch;
  height: 60ch;
}

.card5 {
  width: 60ch;
  height: 60ch;
}

.container {
  width: 100%;
  height: 800px;

  /* background: tomato; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/_background/background-pink.png");
}
